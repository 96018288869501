.form_quote {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-width: 360px;
  min-height: 100%;
  margin: 0;
  background: url("/_/images/layout/body.jpg") top center no-repeat;
  background-size: cover;
  color: #333; }
  @media (min-width: 768px) {
    body {
      height: 100%;
      padding-bottom: 72px; }
      body .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.block {
  padding: 20px 0; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.banner {
  position: relative;
  z-index: 99000; }

.jumbo {
  position: relative;
  z-index: 70000; }
  @media (min-width: 992px) {
    .jumbo .row {
      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center; }
    .jumbo .jumbo_marketing {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .jumbo .jumbo_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

html {
  font-size: 16px;
  line-height: 1.6em;
  -webkit-text-size-adjust: 100%; }

body {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  @media (min-width: 768px) {
    body {
      font-size: 1.1rem; } }
  @media (min-width: 768px) {
    body {
      font-size: 1.2rem; } }

h1, h2, h3, h4, h5, h6 {
  margin: 1.5em 0 0.5em;
  font-weight: 400;
  line-height: 1.1em; }

h6 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.0125em; }

h5 {
  font-size: 1.2rem; }

h4 {
  font-size: 1.4rem;
  letter-spacing: -0.015em; }

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.0175em; }

h2 {
  font-size: 1.8rem;
  letter-spacing: -0.02em; }

h1 {
  font-size: 2.0rem;
  font-weight: 800;
  letter-spacing: -0.02em; }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 600; }

.bolder, .strong, .b2 {
  font-weight: 800; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 1.15rem;
  border-left: 0.5rem solid; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #ED1C24; }
  blockquote.bq--secondary {
    border-color: #333333; }
  blockquote.bq--highlight {
    border-color: #666666; }
  blockquote.bq--accent {
    border-color: #B9B9B9; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ffc107; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #ED1C24; }
  a:hover, a:focus {
    color: #af0e14;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #737373; }

.phone {
  text-decoration: none;
  cursor: default;
  color: #333333; }
  .phone:hover {
    text-decoration: none;
    color: #333333;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list_inline li:not(:last-child), .list_unstyled li:not(:last-child), .list_icon li:not(:last-child), .list li:not(:last-child) {
  margin-bottom: 0.25em; }

.list_inline, .list_unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list_inline li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0 !important; }

.list_buttons a {
  border-radius: 3px; }

.list_icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  .list_icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

img {
  display: block;
  width: 100%;
  border-style: none; }

.img--caption span {
  display: block;
  margin: 5px 0 0;
  line-height: 1.1em; }

.img--main {
  margin: 0 0 15px;
  max-width: 360px; }

.img--thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 576px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 15px; } }

@media (min-width: 576px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 15px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--coupon {
  max-width: 640px; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block; }

.mb0 {
  margin-bottom: 0 !important; }

.mb15 {
  margin-bottom: 15px !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase; }

.txt--cap {
  text-transform: capitalize; }

.txt--lg {
  font-size: 115%; }

.txt--md {
  font-size: 16; }

.txt--sm {
  font-size: 85%; }

.lh1 {
  line-height: 1.1em; }

.lh125 {
  line-height: 1.25em; }

.txt--left {
  text-align: left; }

.txt--right {
  text-align: right; }

.txt--center {
  text-align: center; }

.txt--wb0 {
  font-weight: 400; }

.txt--wb1 {
  font-weight: 600; }

.txt--wb2 {
  font-weight: 800; }

.color--text {
  color: #333; }

.color--white {
  color: #fff; }

.color--primary {
  color: #ED1C24; }

.color--secondary {
  color: #333333; }

.color--highlight {
  color: #666666; }

.color--link {
  color: #ED1C24; }

.color--review {
  color: #fd7e14; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffc107; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #333; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #ED1C24; }

.bg--secondary {
  background-color: #333333; }

.bg--highlight {
  background-color: #666666; }

.bg--link {
  background-color: #ED1C24; }

.bg--review {
  background-color: #fd7e14; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffc107; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary, .page-title {
  background: #ED1C24;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ED1C24; }

.gdfancybg--secondary {
  background: #333333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #333333; }

.gdfancybg--highlight {
  background: #666666;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #666666; }

.gdfancybg--review {
  background: #fd7e14;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #fd7e14; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.25) 90%, rgba(0, 0, 0, 0.25) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(200, 200, 200, 0.1) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(200, 200, 200, 0.1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 25%, rgba(200, 200, 200, 0.1) 50%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.block-main {
  padding: 1.5em 0;
  clear: both; }
  .block-main .block_title {
    margin-top: 0; }

.jumbo {
  color: #fff; }
  @media (min-width: 992px) {
    .jumbo .jumbo_marketing {
      padding-right: 5px; }
    .jumbo .jumbo_conversion {
      padding-left: 5px; } }

[class*="btn"] {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #959595;
  background-color: #a8a8a8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border-radius: 3px;
  cursor: pointer; }
  [class*="btn"]:hover {
    background-color: #c8c8c8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.list_buttons a, .btn_link {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #af0e14;
  background-color: #d21118;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .list_buttons a:hover, .btn_link:hover {
    background-color: #ef343b;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_primary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #8b0b10;
  background-color: #c00f16;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_primary:hover {
    background-color: #f03f46;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_secondary {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #0d0d0d;
  background-color: #202020;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_secondary:hover {
    background-color: #404040;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_highlight {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #404040;
  background-color: #535353;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_highlight:hover {
    background-color: #737373;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_accent {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #939393;
  background-color: #a6a6a6;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_accent:hover {
    background-color: #c6c6c6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_success {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #19692c;
  background-color: #218838;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_success:hover {
    background-color: #2dbc4e;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_danger {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #a71d2a;
  background-color: #c82333;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_danger:hover {
    background-color: #e04b59;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_info {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #4709ac;
  background-color: #560bd0;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_info:hover {
    background-color: #7528f3;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_review {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #c35a02;
  background-color: #e96b02;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_review:hover {
    background-color: #fd8c2d;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_warning {
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border: 1px solid #ba8b00;
  background-color: #e0a800;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_warning:hover {
    background-color: #ffc721;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.list_buttons a, .btn--sm {
  padding: 0.2em 0.75em;
  font-size: 0.85rem; }

.btn--wide {
  display: block; }

.btn_3d {
  padding: 0.75rem 1rem;
  border-width: 0.05rem;
  border-bottom-width: 0.25rem;
  -webkit-transition: all ease-in-out 150ms;
  -moz-transition: all ease-in-out 150ms;
  -ms-transition: all ease-in-out 150ms;
  -o-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms; }
  .btn_3d:hover {
    padding: 0.85rem 1rem;
    border-bottom-width: 0.05rem;
    -webkit-transition: all ease-in-out 150ms;
    -moz-transition: all ease-in-out 150ms;
    -ms-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }

.form {
  font-size: 1em; }
  .form label {
    color: #333;
    font-size: 14px;
    font-weight: 600; }
  .form .alert {
    margin: 0 15px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    border: 1px solid #ccc;
    color: #333; }

.form_group-wrap {
  box-shadow: none;
  border: 1px solid #ddd;
  margin: 0 0 1.25rem;
  padding: 0 0.25rem 0.75rem; }
  .form_group-wrap p {
    padding: 0 0.75rem; }

.group_title {
  display: block;
  width: 70%;
  padding: 0.5rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  background: #f5f5f5; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75rem 0.5rem;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  padding: 0 0.75em 0.1em; }
  .form_group.form_hp {
    display: none; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: 0 0 0.5em;
  padding: 0 0 0 3px !important;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #d32535; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }

.form_quote {
  padding: 0 0 0.75rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.5rem;
    padding: 0.75rem;
    background-color: #ED1C24;
    color: #fff; }

.branding_logo {
  display: block;
  width: 100%;
  max-width: 360px;
  margin: 0 auto; }
  .branding_logo svg #box {
    fill: #333; }
  .branding_logo svg #water {
    fill: #ED1C24; }
  .branding_logo svg #heater {
    fill: #333333; }
  @media (min-width: 992px) {
    .branding_logo {
      max-width: 480px;
      margin-left: 0; } }

.page-title {
  position: relative;
  z-index: 70000;
  padding: 2em 0;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title h1 {
    margin: 0;
    line-height: 1em;
    text-align: center; }
  @media (min-width: 768px) {
    .page-title h1 {
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    .page-title {
      padding: 7em 0 1.5em; }
      .page-title h1 {
        text-align: left; } }
  @media (min-width: 1200px) {
    .page-title h1 {
      font-size: 2.75rem; } }
  .post .page-title h1 {
    max-width: 730px; }

.copyright_data ul > li {
  line-height: 1.1em; }

.copyright_data a {
  color: #fff;
  font-weight: 600;
  text-decoration: none; }

@media (min-width: 576px) {
  .copyright_data ul > li {
    display: inline-block; }
    .copyright_data ul > li:not(:last-child) {
      margin-right: 0.75em; } }

.banner {
  padding: 2em 0 1.9em;
  border-bottom: 5px solid #ddd;
  box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  color: #333; }

.site_info {
  margin-top: 1em;
  border-top: 5px solid #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #333333;
  color: #fff;
  font-size: 0.9em; }

.highlight {
  background-color: rgba(0, 0, 0, 0.7);
  box-decoration-break: clone;
  box-shadow: 5px 0 0 rgba(0, 0, 0, 0.7), -5px 0 0 rgba(0, 0, 0, 0.7);
  display: inline;
  padding: 5px;
  line-height: 1.6em; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }
