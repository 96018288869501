.jumbo {

	color: $white;

	@include media-breakpoint-up(lg) {
		.jumbo_marketing { padding-right: 5px; }
		.jumbo_conversion { padding-left: 5px; }
	}

}

	.jumbo_marketing {}

	.jumbo_conversion {}