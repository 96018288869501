//.title_branding {}

	.branding_logo {

		display: block;
		width: 100%;
		max-width: 360px;
		margin: 0 auto;

		svg #box { fill: #333; }
		svg #water { fill: $color-primary; }
		svg #heater { fill: $color-secondary; }

		@include media-breakpoint-up(lg) {
			max-width: 480px;
			margin-left: 0;
		}

	}