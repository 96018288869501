$banner-bg: 			$white !default;
$banner-color: 			$color-text;

.banner {
	padding: 2em 0 1.9em;
	border-bottom: 5px solid #ddd;
	box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
	background-color: $banner-bg;
	color: $banner-color;

}